import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { utils } from '../utils/utils';
import { DefaultResponse } from './domains/calculator.domain';
import { ManualDrawingInDto, Monitoreo, PlatformDataView, PlatformFieldView, PlatformIndicesView, PlatformMonitoringViewData, PlatformSpaListRaster, UpdateAttributeMonitoringIn, UpdateManualDrawingInDto } from './domains/platform.service.domain';
import { Observable, Subject, catchError, map, of } from 'rxjs';
import { PlatformCultivos } from '../pages/platform/platform.domain';
import { CreateMonitoringOut, UserFieldDto } from '../pages/platform/monitoring/monitoring.domain';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  private cachedData: { [key: string]: any } = {};
  public changeProducerTupla: Subject<string> = new Subject<string>();


  constructor(private readonly httpC: HttpClient) { }

  clearCache() {
    this.cachedData = {};
  }

  private cacheOrFetchData(key: string, request: Observable<any>): Observable<any> {
    if (this.cachedData[key]) {
      return of(this.cachedData[key]);
    } else {
      return request.pipe(
        map((data: any) => {
          this.cachedData[key] = data;
          return data;
        }),
        catchError((error: any) => {
          // Handle error appropriately
          console.error(error);
          return of(null);
        })
      );
    }
  }

  public clearCacheForKey(key: string) { //TODO: agregar un enum para las keys que se pueden borrar (indices es una)
    delete this.cachedData[key];
  }


  public emitChangeProducerTupla(route: string) {
    this.changeProducerTupla.next(route);
  }

  public getChangedTupla(): Observable<string> {
    return this.changeProducerTupla.asObservable();
  }


  getCampaignsByProducer() {
    return this.httpC.get<DefaultResponse<{ IdCampaign: number, Name: string, Year: number }[]>>(`${environment.platformApi}campaign/explorer?idClient=${this.getClientProducerSelected().oldClientID}&idProducer=${this.getClientProducerSelected().producerID}`);
  }

  getCultivoForCultivation(campaign: number, field: number) {
    return this.httpC.get<DefaultResponse<{ content: any }>>(`${environment.platformApi}cultivation/field/crop?idClient=${this.getClientProducerSelected().oldClientID}&idProducer=${this.getClientProducerSelected().oldProducerID}&idCampaign=${campaign}&idField=${field}`);
  }

  getEvolCultivoChart(idCampaign: number, idField: number, crop: string, mesInicio: number, mesFin: number, anioInicio: number, anioFin: number, uuidLotes: string[], type: number) {
    return this.httpC.get<DefaultResponse<any>>(`${environment.platformApi}cultivation/crop/filter?idProducer=${this.getClientProducerSelected().oldProducerID}&idCampaign=${idCampaign}&idField=${idField}&crop=${crop}&startMoths=${mesInicio}&endMoths=${mesFin}&startYear=${anioInicio}&endYear=${anioFin}&uuidLotes=${uuidLotes.join(',')}&type=${type}`);
  }

  getEvolCultivoCampChart(idCampaign: number, idField: number, crop: string, mesInicio: number, mesFin: number, anioInicio: number, anioFin: number, type: number, inicCamp: number, finCamp: number) {
    return this.httpC.get<DefaultResponse<any>>(`${environment.platformApi}cultivation/crop/filter/campaign?idProducer=${this.getClientProducerSelected().oldProducerID}&idCampaign=${idCampaign}&idField=${idField}&crop=${crop}&startMoths=${mesInicio}&endMoths=${mesFin}&startYear=${anioInicio}&endYear=${anioFin}&type=${type}&startCampaign=${inicCamp}&endCampaign=${finCamp}`);
  }

  getPlantillasFromData(idCampaign: number, idField: number, idLot: number) {
    return this.httpC.get<DefaultResponse<PlatformDataView>>(`${environment.platformApi}cultivation/template/${idCampaign}/${idField}/${idLot}?idClient=${this.getClientProducerSelected().oldClientID}&idProducer=${this.getClientProducerSelected().oldProducerID}`);
  }

  getEvolCultivoCropLots(idCampaign: number, idField: number, crop: string) {
    return this.httpC.get<DefaultResponse<{ content: any[] }>>(`${environment.platformApi}cultivation/crop/lots?idProducer=${this.getClientProducerSelected().oldProducerID}&idCampaign=${idCampaign}&idField=${idField}&crop=${crop}`);

  }


  getIndicesViewData(idCampaign: number, idField: number, idLot: number): Observable<any> {
    return this.cacheOrFetchData('indices', this.httpC.get<DefaultResponse<PlatformIndicesView>>(`${environment.platformApi}cultivation/layer/${idCampaign}/${idField}/${idLot}?idClient=${this.getClientProducerSelected().oldClientID}&idProducer=${this.getClientProducerSelected().oldProducerID}`));
  }

  getMonitoringViewData(idCampaign: number, idField: number, idLot: number) {
    return this.httpC.get<DefaultResponse<PlatformMonitoringViewData>>(`${environment.platformApi}cultivation/plot/${idCampaign}/${idField}/${idLot}?idClient=${this.getClientProducerSelected().oldClientID}&idProducer=${this.getClientProducerSelected().oldProducerID}`);
  }

  getFieldViewData() {
    return this.httpC.get<DefaultResponse<PlatformFieldView>>(`${environment.platformApi}cultivation/data?idClient=${this.getClientProducerSelected().oldClientID}&idProducer=${this.getClientProducerSelected().oldProducerID}`);
  }

  getFielViewDataFromApi(idUser: number, data: UserFieldDto){
    return this.httpC.post(`${environment.api}backoffice/user/filter/user/${idUser}`, data);
  }

  getTrackingAreaData(uuid: string) {
    return this.httpC.get<DefaultResponse<{ content: number }>>(`${environment.platformApi}cultivation/area-tracking?uuid=${uuid}&idClient=${this.getClientProducerSelected().oldClientID}&idProducer=${this.getClientProducerSelected().oldProducerID}`);
  }

  getSPAListRasterNDVI(idCampaign: number, idField: number, idLot: number): Observable<any> {
    return this.cacheOrFetchData('SPAListNDVI', this.httpC.get<DefaultResponse<{ content: PlatformSpaListRaster[] }>>(`${environment.platformApi}cultivation/plot/spa/${idCampaign}/${idField}/${idLot}?idClient=${this.getClientProducerSelected().oldClientID}&idProducer=${this.getClientProducerSelected().oldProducerID}&rasterTypeFilter=${1}`));
  }

  getSPAListRasterClorofila(idCampaign: number, idField: number, idLot: number): Observable<any> {
    return this.cacheOrFetchData('SPAListCLOROFILA', this.httpC.get<DefaultResponse<{ content: PlatformSpaListRaster[] }>>(`${environment.platformApi}cultivation/plot/spa/${idCampaign}/${idField}/${idLot}?idClient=${this.getClientProducerSelected().oldClientID}&idProducer=${this.getClientProducerSelected().oldProducerID}&rasterTypeFilter=${8}`));
  }

  getRapidoImagen(id: string) {
    return this.httpC.get(`${environment.platformApi}cultivation/GetPointOfInterest/${id}?idClient=${this.getClientProducerSelected().oldClientID}&idProducer=${this.getClientProducerSelected().oldProducerID}`, {
      responseType: "blob"
    });
  }

  getClimate(idCampo: number) {
    return this.httpC.get<DefaultResponse<{ content: any }>>(`${environment.platformApi}climate/${idCampo}?idProducer=${this.getClientProducerSelected().oldProducerID}`);
  }

  getHumidity(idCampo: number) {
    return this.httpC.get<DefaultResponse<{ content: string }>>(`${environment.platformApi}climate/humidity/${idCampo}?idProducer=${this.getClientProducerSelected().oldProducerID}`);
  }

  getPrecipitacionDate(idCampo: number, date: string) {
    return this.httpC.get<DefaultResponse<any>>(`${environment.platformApi}climate/precipitation/date/${idCampo}/${date}?idProducer=${this.getClientProducerSelected().oldProducerID}`);
  }

  getPrecipitacionMonths(idCampo: number, month: string, year: number) {
    return this.httpC.get<DefaultResponse<any>>(`${environment.platformApi}climate/precipitation/moths/${idCampo}/${month}/${year}?idProducer=${this.getClientProducerSelected().oldProducerID}`);
  }

  getPrecipitacionMonthsFilter(idCampo: number, startMonth: string, endMoths: string, startYear: number, endYear: number) {
    return this.httpC.get<DefaultResponse<any>>(`${environment.platformApi}climate/precipitation/filter/${idCampo}/${startMonth}/${endMoths}/${startYear}/${endYear}?idProducer=${this.getClientProducerSelected().oldProducerID}`);
  }

  getZonesFromProducerAndCampaign(idCampaign: number) {
    return this.httpC.get<DefaultResponse<any>>(`${environment.platformApi}cultivation/zones?idProducer=${this.getClientProducerSelected().oldProducerID}&idCampaign=${idCampaign}`);
  }

  getSubzonesFromProducerAndCampaign(zones: number[]) {
    return this.httpC.get<DefaultResponse<any>>(`${environment.platformApi}cultivation/sub-zones?idProducer=${this.getClientProducerSelected().oldProducerID}&zones=${zones.join(',')}`);
  }

  getCampoFilteredProducerAndCampaign(idCampaign: number, fields: number[], opt: { zone: number | string, subzone: number | string, cultivo: string }) {
    return this.httpC.get<DefaultResponse<any>>(`${environment.platformApi}cultivation/fields/filter?idProducer=${this.getClientProducerSelected().oldProducerID}&idCampaign=${idCampaign}&field=${fields.join(',')}&zone=${opt.zone}&subZone=${opt.subzone}&cultive=${opt.cultivo}`);
  }

  getCultivosFromProducerAndCampaign(idCampaign: number) {
    return this.httpC.get<DefaultResponse<{ content: PlatformCultivos[] }>>(`${environment.platformApi}cultivation/crops?idProducer=${this.getClientProducerSelected().oldProducerID}&idCampaign=${idCampaign}`);
  }

  getConfigOfFieldView() {
    return this.httpC.get<DefaultResponse<{ content: PlatformCultivos[] }>>(`${environment.platformApi}cultivation/config?idProducer=${this.getClientProducerSelected().oldProducerID}`);
  }

  getClientProducerSelected() {
    return utils.getClientProducerSelected();
  }

  createFastMonitoring(newMonitorings: CreateMonitoringOut[], idField: number, idPlot: number, idCampaign: number) {
    return this.httpC.post<DefaultResponse<any>>(`${environment.platformApi}cultivation/fast/monitoring?idProducer=${this.getClientProducerSelected().oldProducerID}&idField=${idField}&idPlot=${idPlot}&idCampaign=${idCampaign}`, newMonitorings);
  }

  updateFastMonitoring(idPoi: number, description: string, title: string) {
    return this.httpC.put<DefaultResponse<any>>(`${environment.platformApi}cultivation/fast/monitoring`, {
      idPoi,
      description,
      title
    });
  }

  deleteFastMonitoring(id: number) {
    return this.httpC.delete<DefaultResponse<any>>(`${environment.platformApi}cultivation/fast/monitoring?idMonitoring=${id}&idProducer=${this.getClientProducerSelected().oldProducerID}&idClient=${this.getClientProducerSelected().oldClientID}`);
  }

  getAssociatedMonitoringTemplates() {
    return this.httpC.get<DefaultResponse<any>>(`${environment.platformApi}cultivation/spa/list/groups?idProducer=${this.getClientProducerSelected().oldProducerID}&idClient=${this.getClientProducerSelected().oldClientID}`);
  }

  createAssociatedMonitoring(
    newMonitorings: CreateMonitoringOut[],
    idField: number,
    idPlot: number,
    idCampaign: number,
    groupTitle: string,
    idTemplate: number
  ) {
    return this.httpC.post<DefaultResponse<any>>(`${environment.platformApi}cultivation/associated/monitoring?idProducer=${this.getClientProducerSelected().oldProducerID}&idField=${idField}&idPlot=${idPlot}&idCampaign=${idCampaign}&groupTitle=${groupTitle}&validateTitle=true&idTemplate=${idTemplate}`, newMonitorings);
  }

  deleteAllAssociatedGroup(groupTitle: string, idField: number, idPlot: number, idCampaign: number) {
    return this.httpC.delete<DefaultResponse<any>>(`${environment.platformApi}cultivation/associated/monitoring/${groupTitle}?idProducer=${this.getClientProducerSelected().oldProducerID}&idField=${idField}&idPlot=${idPlot}&idCampaign=${idCampaign}`);
  }

  deleteAssociatedGroupByTitle(groupTitle: number, title: number, idField: number, idPlot: number, idCampaign: number) {
    return this.httpC.delete<DefaultResponse<any>>(`${environment.platformApi}cultivation/associated/monitoring/${groupTitle}/${title}?idProducer=${this.getClientProducerSelected().oldProducerID}&idField=${idField}&idPlot=${idPlot}&idCampaign=${idCampaign}`);
  }

  deleteAssociatedMonitoringCategory(idMonitoring: number, idField: number, idPlot: number, idCampaign: number) {
    return this.httpC.delete<DefaultResponse<any>>(`${environment.platformApi}cultivation/associated/monitoring/category/${idMonitoring}?idProducer=${this.getClientProducerSelected().oldProducerID}&idField=${idField}&idPlot=${idPlot}&idCampaign=${idCampaign}`);
  }

  exportFastMonitoring(datesSelected: any, idCampaign: number, idField: number, idPlot: number) {
    return this.httpC.get<DefaultResponse<any>>(`${environment.platformApi}cultivation/reports/zip/fast/monitoring?idProducer=${this.getClientProducerSelected().oldProducerID}&idField=${idField}&idPlot=${idPlot}&idCampaign=${idCampaign}&dates=${datesSelected}`,
      { responseType: 'arraybuffer' as 'json' });
  }

  exportAssociatedMonitoring(idCampaign: number, idField: number, idPlot: number) {
    return this.httpC.get<DefaultResponse<any>>(`${environment.platformApi}cultivation/reports/zip/associated/monitoring?idProducer=${this.getClientProducerSelected().oldProducerID}&idField=${idField}&idPlot=${idPlot}&idCampaign=${idCampaign}`,
      { responseType: 'arraybuffer' as 'json' });
  }

  exportTracksMonitoring(idCampaign: number, idField: number, idPlot: number) {
    return this.httpC.get<DefaultResponse<any>>(`${environment.platformApi}cultivation/download/tracks?idProducer=${this.getClientProducerSelected().oldProducerID}&idField=${idField}&idPlot=${idPlot}&idCampaign=${idCampaign}`,
    {responseType: 'arraybuffer' as 'json'});
  }

  exportRendimientos(idCampaign: number, idField: number, plots: number[], dates: string[]) {
    return this.httpC.post<DefaultResponse<any>>(`${environment.platformApi}campaign/explorer/report/estimaciones?idProducer=${this.getClientProducerSelected().oldProducerID}&idField=${idField}&idCampaign=${idCampaign}`,
      {
        plots,
        dates
      },
      { responseType: 'arraybuffer' as 'json' });
  }


  

  savePresipitation(idField: number, fecha: string, presip: number, granizo: boolean | undefined, helada: boolean | undefined) {
    return this.httpC.post<DefaultResponse<any>>(`${environment.platformApi}climate/precipitation`, {
      idProducer: this.getClientProducerSelected().oldProducerID,
      idField: idField,
      date: fecha,
      precipitation: presip,
      frost: helada,
      hail: granizo
    });
  }

  deletePresipitation(idField: number, fecha: string) {
    return this.httpC.delete<DefaultResponse<any>>(`${environment.platformApi}climate/precipitation`, {
      body: {
        idProducer: this.getClientProducerSelected().oldProducerID,
        idField: idField,
        date: fecha
      }
    });
  }


  addAtributeForDataView(idTemplate: number, idCampaign: number, idField: number, idLot: number, sheetKey: string, typeEnvironment: string, value: number, type: string) {
    return this.httpC.post<DefaultResponse<any>>(`${environment.platformApi}cultivation/template/${idTemplate}?idProducer=${this.getClientProducerSelected().oldProducerID}&idClient=${this.getClientProducerSelected().oldClientID}`, {
      idCampaign,
      idField,
      idLot,
      sheetKey,
      typeEnvironment,
      value,
      type
    });
  }

  updateAtributeForDataView(idAtribb: number, value: number, type: string,idCampaign: number, idField: number, idLot: number, typeEnvironment: string) {
    return this.httpC.put<DefaultResponse<any>>(`${environment.platformApi}cultivation/template/${idAtribb}?idProducer=${this.getClientProducerSelected().oldProducerID}&idClient=${this.getClientProducerSelected().oldClientID}`, {
      idCampaign,
      idField,
      idLot,
      typeEnvironment,
      value,
      type
    });
  }

  deleteAtributeForDataView(idAtribb: number, type: string) {
    return this.httpC.put<DefaultResponse<any>>(`${environment.platformApi}cultivation/template/${idAtribb}?idProducer=${this.getClientProducerSelected().oldProducerID}&idClient=${this.getClientProducerSelected().oldClientID}`, {
      value: -1,
      type
    });
  }

  editAttributeForDataView(data: UpdateAttributeMonitoringIn){
    return this.httpC.put<DefaultResponse<any>>(`${environment.platformApi}cultivation/monitoring/attribute/${data.idAtt}?idProducer=${this.getClientProducerSelected().oldProducerID}`, data);
  }
  getReportPdfDataView(idCampaign:number, idField: number, IdLot: number, categoriaTemplate: number){
    return this.httpC.get<DefaultResponse<any>>(`${environment.platformApi}cultivation/template/report/pdf?idProducer=${this.getClientProducerSelected().oldProducerID}&idField=${idField}&idPlot=${IdLot}&idCampaign=${idCampaign}&idClient=${this.getClientProducerSelected().oldClientID}&reportType=true&categorySelected=${categoriaTemplate}`,
      { responseType: 'arraybuffer' as any });
  }

  getPrescriptionDataView(idCampaign: number, idField: number, IdLot: number, fileName: string, ultimaCapaLote: number, atributos: number[], sheetTemplate: number) {
    return this.httpC.get<DefaultResponse<any>>(`${environment.platformApi}cultivation/template/prescription/map/zip?idProducer=${this.getClientProducerSelected().oldProducerID}&idField=${idField}&idPlot=${IdLot}&idCampaign=${idCampaign}&idClient=${this.getClientProducerSelected().oldClientID}&planillaNueva=true&FileName=${fileName}&idPlotCampaignLayerHeader=${ultimaCapaLote}&arrAttributes=${atributos.join(',')}&catSheetSeleccionada=${sheetTemplate}`,
      { responseType: 'arraybuffer' as 'json' });
  }

  getStaticLayerOptions(IdField: number, IdLot?: number) {
    return this.httpC.get<DefaultResponse<any>>(`${environment.platformApi}campaign/explorer/shape/file/layer?idProducer=${this.getClientProducerSelected().oldProducerID}&idField=${IdField}&idPlot=${IdLot}`)
  }

  preUploadLotLayer(idField: number, idLot: number, layersType: string, lotName: string, nameLayer: string, idLayerType: number, file: File) {
    const fData = new FormData()
    fData.append('file', file, file.name)
    fData.append('idProducer', this.getClientProducerSelected().oldProducerID)
    fData.append('idField', idField.toString())
    fData.append('idPlot', idLot.toString())
    fData.append('layersTypeAlreadyAssigned', layersType)
    fData.append('plotName', lotName)
    fData.append('handlerChangeLayerType', 'onLayerTypeChange')
    fData.append('name', nameLayer)
    fData.append('idLayerType', idLayerType.toString());
    return this.httpC.post<DefaultResponse<any>>(`${environment.platformApi}cultivation/upload/shape?uploadTableToDB=true&isShpPerformance=N`, fData,
      {
        headers: { enctype: 'multipart/form-data' },
      }
    );
  }

  uploadLotLayer(idField: number, idLot: number, layersType: string, lotName: string, nameLayer: string, idLayerType: number, pathShapeFile: string, ogrInfoExtent: string, ogrInfoComplete: string, shapeFileName: string, featureCount: string, attributesShapeFile: string,
    geometryType: {
    id: number;
    shapeFileType: string;
    posGISType: string;
    mapServerType: string;
  }) {
    const fData = new FormData()
    fData.append('btnSaveAndColourize', 'Grabar y Colorear')
    fData.append('idProducer', this.getClientProducerSelected().oldProducerID)
    fData.append('idField', idField.toString())
    fData.append('idPlot', idLot.toString())
    fData.append('layersTypeAlreadyAssigned', layersType)
    fData.append('plotName', lotName)
    fData.append('handlerChangeLayerType', 'onLayerTypeChange')
    fData.append('name', nameLayer)
    fData.append('idLayerType', idLayerType.toString());
    fData.append('pathShapeFile', pathShapeFile);
    fData.append('ogrInfoExtent', ogrInfoExtent);
    fData.append('ogrInfoComplete', ogrInfoComplete);
    fData.append('shapeFileName', shapeFileName);
    fData.append('featureCount', featureCount);
    fData.append('attributesShapeFile', attributesShapeFile);
    fData.append('geometryType.id', geometryType.id.toString());
    fData.append('geometryType.shapeFileType', geometryType.shapeFileType);
    fData.append('geometryType.posGISType', geometryType.posGISType);
    fData.append('geometryType.mapServerType', geometryType.mapServerType);
    return this.httpC.post<DefaultResponse<any>>(`${environment.platformApi}campaign/explorer/save/shape/file/layer`, fData,
      {
        headers: { enctype: 'multipart/form-data' },
      }
    );
  }

  downloadLayerField(idField: number, uuidLayers: string[]) {
    return this.httpC.get(`${environment.platformApi}campaign/explorer/generate/pdf/field/layers?uuidLayers=${uuidLayers.join(',')}&idProducer=${this.getClientProducerSelected().oldProducerID}&idField=${idField}`,
    { responseType: 'arraybuffer' as 'json' })
  }

  preUploadFieldLayer(idField: number,layersType: string, fieldName: string, nameLayer: string, idLayerType: number, file: File) {
    const fData = new FormData()
    fData.append('file', file, file.name)
    fData.append('idProducer', this.getClientProducerSelected().oldProducerID)
    fData.append('idField', idField.toString())
    fData.append('layersTypeAlreadyAssigned', layersType)
    fData.append('fieldName', fieldName)
    fData.append('handlerChangeLayerType', 'onLayerTypeChange')
    fData.append('name', nameLayer)
    fData.append('idLayerType', idLayerType.toString());
    fData.append('estimationElaborationDate','01/01/0001')
    fData.append('satelliteDate','01/01/0001')
    fData.append('satelliteSensorType','-1')
    fData.append('idRasterIndexType','-1')
    fData.append('rasterFileName','')
    return this.httpC.post<DefaultResponse<any>>(`${environment.platformApi}cultivation/upload/shape/field?uploadTableToDB=true&isShpPerformance=N`, fData,
      {
        headers: { enctype: 'multipart/form-data' },
      }
    );
  }

  uploadFieldLayer(idField: number, layersType: string, fieldName: string, nameLayer: string, idLayerType: number, pathShapeFile: string, ogrInfoExtent: string, ogrInfoComplete: string, shapeFileName: string, featureCount: string, attributesShapeFile: string,
    geometryType: {
    id: number;
    shapeFileType: string;
    posGISType: string;
    mapServerType: string;
  }) {
    const fData = new FormData()
    fData.append('btnSaveAndColourize', 'Grabar y Colorear')
    fData.append('idProducer', this.getClientProducerSelected().oldProducerID)
    fData.append('idField', idField.toString())
    fData.append('layersTypeAlreadyAssigned', layersType)
    fData.append('fieldName', fieldName)
    fData.append('handlerChangeLayerType', 'onLayerTypeChange')
    fData.append('name', nameLayer)
    fData.append('idLayerType', idLayerType.toString());
    fData.append('estimationElaborationDate','01/01/0001')
    fData.append('satelliteDate','01/01/0001')
    fData.append('satelliteSensorType','-1')
    fData.append('idRasterIndexType','-1')
    fData.append('rasterFileName','(empty)')
    fData.append('pathShapeFile', pathShapeFile);
    fData.append('ogrInfoExtent', ogrInfoExtent);
    fData.append('ogrInfoComplete', ogrInfoComplete);
    fData.append('shapeFileName', shapeFileName);
    fData.append('featureCount', featureCount);
    fData.append('attributesShapeFile', attributesShapeFile);
    fData.append('geometryType.id', geometryType.id.toString());
    fData.append('geometryType.shapeFileType', geometryType.shapeFileType);
    fData.append('geometryType.posGISType', geometryType.posGISType);
    fData.append('geometryType.mapServerType', geometryType.mapServerType);
    return this.httpC.post<DefaultResponse<any>>(`${environment.platformApi}campaign/explorer/save/shape/file/layer`, fData,
      {
        headers: { enctype: 'multipart/form-data' },
      }
    );
  }

  exportKmlData(idCamp: number, idField: number) {
    return this.httpC.get(`${environment.platformApi}campaign/explorer/report/kml?idProducer=${this.getClientProducerSelected().oldProducerID}&idCampaign=${idCamp}&idField=${idField}`, { responseType: 'blob' as 'json' })
  }

  exportExcelField(idCamp: number, idField: number, idLot: any){
    return this.httpC.get(`${environment.platformApi}cultivation/template/report/excel?idProducer=${this.getClientProducerSelected().oldProducerID}&idField=${idField}&idCampaign=${idCamp}&lotes=${idLot}`, { responseType: 'blob' as 'json' })
  }

  interpolateNDVI(idCampaign: number, idField: number, idLot: number, idRaster: number, startDate: string, endDate:string,isNewMonitoring: boolean,idMonitoringDara:number,uuidRaster: string,idGrupoTemplate:number) {
    return this.httpC.post<DefaultResponse<any>>(`${environment.platformApi}campaign/explorer/interpolante/plot/ndvi?idProducer=${this.getClientProducerSelected().oldProducerID}`, {
      idCampaign,
      idField,
      idPlot:idLot,
      idRaster,
      startDate,
      endDate,
      isNewMonitoring,
      idMonitoringDara,
      uuidRaster,
      idGrupoTemplate
    });
  }

  uploadPlanillaCultivo(idField: number, file: File) {
    const fData = new FormData()
    fData.append('file', file, file.name)
    return this.httpC.put<DefaultResponse<any>>(`${environment.platformApi}cultivation/template/import/excel?idProducer=${this.getClientProducerSelected().oldProducerID}&idField=${idField}`, fData,
      {
        headers: { enctype: 'multipart/form-data' },
      }
    );
  }

  uploadAttachmentFile(idField: number,idPlot: number, idCampaign: number, description: string, file: File) {
    const fData = new FormData()
    fData.append('file', file, file.name)
    return this.httpC.post<DefaultResponse<any>>(`${environment.platformApi}cultivation/upload/attachment?idProducer=${this.getClientProducerSelected().oldProducerID}&idField=${idField}&idPlot=${idPlot}&idCampaign=${idCampaign}&description=${description}`, fData,
      {
        headers: { enctype: 'multipart/form-data' },
      },
    );
  }

  downloadAttachmentFile(idField: number,idPlot: number, idCampaign: number, idFile: number ) {
    return this.httpC.get(`${environment.platformApi}cultivation/download/attachment/${idFile}?idProducer=${this.getClientProducerSelected().oldProducerID}&idField=${idField}&idPlot=${idPlot}&idCampaign=${idCampaign}`,
    { responseType: 'arraybuffer' as 'json' })
  }


  deleteAttachmentFile(idField: number,idPlot: number, idCampaign: number, idFile: number) {
    return this.httpC.delete<DefaultResponse<any>>(`${environment.platformApi}cultivation/attachment/${idFile}?idProducer=${this.getClientProducerSelected().oldProducerID}&idField=${idField}&idPlot=${idPlot}&idCampaign=${idCampaign}`);
  }


  createDrawPolygon( data: ManualDrawingInDto) {
    return this.httpC.post<DefaultResponse<any>>(`${environment.platformApi}campaign/explorer/manual/drawing?idProducer=${this.getClientProducerSelected().oldProducerID}`, data);
  }

  updateDrawPolygon(idDraw: number, data: UpdateManualDrawingInDto) {
    return this.httpC.put<DefaultResponse<any>>(`${environment.platformApi}campaign/explorer/manual/drawing/${idDraw}?idProducer=${this.getClientProducerSelected().oldProducerID}`,
      data);
  }

  deleteDrawPolygon(idPolygon: number) {
    return this.httpC.delete<DefaultResponse<any>>(`${environment.platformApi}campaign/explorer/manual/drawing/${idPolygon}?idProducer=${this.getClientProducerSelected().oldProducerID}`);
  }

  downloadKmlProductor(idCamp: number, crop: string) {
    return this.httpC.get(`${environment.platformApi}campaign/explorer/report/kml/producer?idProducer=${this.getClientProducerSelected().oldProducerID}&idCampaign=${idCamp}&crop=${crop}`, { responseType: 'blob' as 'json' })
  }

  downloadExcelProductor(idCamp: number, crop: string) {
    return this.httpC.get(`${environment.platformApi}cultivation/template/report/excel/producer?idProducer=${this.getClientProducerSelected().oldProducerID}&idCampaign=${idCamp}&crop=${crop}`, { responseType: 'blob' as 'json' })
  }

  downloadExcelCreaProductor(idCamp: number, crop: string) {
    return this.httpC.get(`${environment.platformApi}cultivation/template/report/excel/producer/crea?idProducer=${this.getClientProducerSelected().oldProducerID}&idCampaign=${idCamp}&crop=${crop}`, { responseType: 'blob' as 'json' })
  }

  getFieldLotPolygons(idCamp: number, field: number) {
    return this.httpC.get<DefaultResponse<any>>(`${environment.platformApi}campaign/explorer/polygons?idProducer=${this.getClientProducerSelected().oldProducerID}&idCampaign=${idCamp}&idField=${field}`)
  }

  getNdviForLot(idCamp: number, field: number, lot: number) {
    return this.httpC.get<DefaultResponse<any>>(`${environment.platformApi}campaign/explorer/lot/ndvi?idProducer=${this.getClientProducerSelected().oldProducerID}&idCampaign=${idCamp}&idField=${field}&idLot=${lot}`)
  }


  

  deleteFastInterpolation(idInterpolation: string, idCamp:number, idField: number, idLot: number) {
    return this.httpC.delete<DefaultResponse<any>>(`${environment.platformApi}cultivation/interpolation/${idInterpolation }?idProducer=${this.getClientProducerSelected().oldProducerID}&idCampaign=${idCamp}&idField=${idField}&idPlot=${idLot}`);
  }

  deleteMonitoringPoint(groupTitle: string,idMon: number, idCamp:number, idField: number, idLot: number) {
    return this.httpC.delete<DefaultResponse<any>>(`${environment.platformApi}cultivation/estimation/${groupTitle}/${idMon}?idProducer=${this.getClientProducerSelected().oldProducerID}&idCampaign=${idCamp}&IdField=${idField}&idPlot=${idLot}`);
  }

  deleteMonitoringIndividual(idMonitoring: number, idCamp:number, idField: number, idLot: number) {
    return this.httpC.delete<DefaultResponse<any>>(`${environment.platformApi}cultivation/news/pom/${idMonitoring}?idProducer=${this.getClientProducerSelected().oldProducerID}&idCampaign=${idCamp}&IdField=${idField}&idPlot=${idLot}`);
  }

  getPumaSatelliteImages(idCamp:number, idField: number, idLot: number) {
    return this.httpC.get<DefaultResponse<any>>(`${environment.api}satelite/${this.getClientProducerSelected().oldProducerID}/${idField}/${idLot}/${idCamp}`);
  }
  interpolateNDVIPumaSat(idCampaign: number, idField: number, idLot: number, idImage:string, dateImage:Date, groupDate:string,uuidImage: string,index: string,idMonitoring:number) {
    return this.httpC.post<DefaultResponse<any>>(`${environment.api}interpolate?idProducer=${this.getClientProducerSelected().oldProducerID}&idCampaign=${idCampaign}&idField=${idField}&idLot=${idLot}`, {
      idImage,
      dateImage,
      groupDate,
      uuidImage,
      index,
      idMonitoring
    });
  }
  getInterpolationsFromPuma(idCampaign: number, idField: number, idLot: number) {
    return this.httpC.get<DefaultResponse<any>>(`${environment.api}interpolate?idProducer=${this.getClientProducerSelected().oldProducerID}&idCampaign=${idCampaign}&idField=${idField}&idLot=${idLot}`);
  }

  getNaturalCutsForImage(idImage: string) {
    return this.httpC.post<DefaultResponse<any>>(`${environment.api}interpolate/cuts`,{
      idImage
    });
  }

}

